module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@_3701cf590259f22f8c3a68ea9271489a/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-anchor-links@1.2.1/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_@babel+core_e098292ac5ad2968131b371511bebf0b/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gdpr-cookies@2.0.9_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react_4810a6765f02ffa30622fbbfe0b37a48/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"G-Y810QG76GR","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-do_f66e7f286d3eebb3738fa8d294c074cd/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e32134a354c6e8ce472d6c1c5f840465"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@1_e7c504b4100c7aac2286c69a411d053c/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
